<template>
  <div>
    <v-card-text>
      <template>
        <v-container fluid>
          <v-container>
            <v-title class="text-h3">
              <div
                align="center"
                style=" margin-top: 3%; margin-bottom: 3%; font-weight: bold"
              >
                Select one option....
              </div>
            </v-title>

            <v-row align="center" justify="center">
              <v-col sm="8" md="6">
                <v-container fluid>
                  <v-radio-group v-model="option">
                    <div>
                      <v-row justify="center" class="mb-10">
                        <v-radio @click="activateOption" label="" value="2">
                        </v-radio>

                        <v-chip
                          @click="dineIn"
                          label
                          max-width="100"
                          text-color="black"
                          class=" "
                        >
                          <v-icon left>
                            mdi-food-fork-drink
                          </v-icon>
                          Dine In
                        </v-chip>
                      </v-row>
                    </div>
                    <div>
                      <v-row justify="center" class="mb-10">
                        <v-radio
                          @click="activateOption"
                          label=""
                          value="1"
                        ></v-radio>
                        <v-chip
                          @click="delivery"
                          label
                          text-color="black"
                          class=" "
                        >
                          <v-icon left>
                            mdi-bike-fast
                          </v-icon>
                          Delivery
                        </v-chip>
                      </v-row>
                    </div>
                    <div>
                      <v-row justify="center" class="mb-10">
                        <v-radio
                          @click="activateOption"
                          label=""
                          value="0"
                        ></v-radio>
                        <v-chip
                          @click="takeAway"
                          label
                          text-color="black"
                          class=" "
                        >
                          <v-icon left>
                            mdi-food-takeout-box
                          </v-icon>
                          Pick Up
                        </v-chip>
                      </v-row>
                    </div>
                  </v-radio-group>
                </v-container>
              </v-col>
            </v-row>

            <v-row>
              <v-btn
                color="primary"
                dark
                tile
                style="
                      left: 50%;
                      transform: translateX(-50%);
                      marginTop: 2%;
                      
                      width: 56% "
                @click="activateOption"
              >
                <v-card-text style="color:black; font-weight:bold">
                  View all deals
                </v-card-text>
              </v-btn>
            </v-row>
          </v-container>

          <!-- <v-radio-group v-model="option" row>
            <v-radio @click="activateOption" label="Dine In" value="DineIn">
            </v-radio>
            <v-radio
              @click="activateOption"
              label="Delivery"
              value="Delivery"
            ></v-radio>
            <v-radio
              @click="activateOption"
              label="Takeaway"
              value="TakeAway"
            ></v-radio>
          </v-radio-group> -->
        </v-container>
      </template>
    </v-card-text>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      landingDialog: false,
      column: null,
      option: ""
    };
  },
  created() {
    //  this.$http
    //     .get("/Menus/ResetQueue")

    //    this.activateOption();
    this.menuSubList.splice(0, this.menuSubList.length);
  },
  watch: {},
  computed: {
    ...mapState(["menuSubList"])
  },
  methods: {
   // ...mapMutations(["ADD_ORDER_TYPE"]),

    activateOption() {
      if (this.option == "") {
        alert("Select an option");
      } else {
        //this.ADD_ORDER_TYPE(this.option);
        console.log("this.option - landing:", this.option);
        this.getIn();
      }
    },
    getIn() {
      this.$router.push("/home");
    },
    dineIn() {
      this.option = "2";
      //   this.ADD_ORDER_TYPE(this.option);
      //   console.log("Type:", this.option);
    },
    delivery() {
      this.option = "1";
      //   this.ADD_ORDER_TYPE(this.option);
      //   console.log("Type:", this.option);
    },
    takeAway() {
      this.option = "0";
      //   this.ADD_ORDER_TYPE(this.option);
      //   console.log("Type:", this.option);
    }
  }
};
</script>
